import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';

import { screen } from '@/components/common/breakpoints';
import { isAnchor } from '@/components/common/Buttons/utils';
import { Icon } from '@/components/common/Icon';
import {
  determineDestination,
  VisuallyHidden,
} from '@/components/common/utils';
import { headingSmall, labelLarge, labelMedium } from '@/styles/typography';

import type {
  ButtonLinkType,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  CombinedButtonProps,
  IconButtonProps,
} from '@/components/common/Buttons/shared';
import type { IconSet } from '@/components/common/Icon';

type IconPosition = 'left' | 'right';

type ButtonProps = CombinedButtonProps & {
  icon?: IconSet;
  iconPosition?: IconPosition;
  hideIconOnMobile?: boolean;
};

const IconPosition = ({
  variant,
  size = 'xl',
  icon,
  iconPosition,
  children,
}: ButtonProps) => {
  if (icon) {
    return (
      <>
        {iconPosition === 'left' && (
          <IconCircle size={size} icon={icon} variant={variant} />
        )}
        <span>{children}</span>
        {iconPosition === 'right' && (
          <IconCircle size={size} icon={icon} variant={variant} />
        )}
      </>
    );
  }
  return children;
};

export const Button = (props: ButtonProps) => {
  const {
    variant = 'solid-light',
    icon = null,
    iconPosition = 'right',
    hideIconOnMobile = false,
    size = 'xl',
    children,
  } = props;

  const commonInner = (
    <IconPosition
      variant={variant}
      size={size}
      icon={icon}
      iconPosition={iconPosition}
    >
      {children}
    </IconPosition>
  );

  if (isAnchor(props)) {
    const { 'aria-label': ariaLabel } = props;
    const destination =
      typeof props?.href === 'string' && determineDestination(props?.href);

    return (
      <CoreLinkButton
        {...props}
        variant={variant}
        size={size}
        rel={destination.isExternalLink ? 'external noopener' : null}
        aria-label={ariaLabel ? `${ariaLabel} ${destination.newTabText}` : null}
        target={destination.target}
        iconPosition={icon ? iconPosition : null}
        hideIconOnMobile={hideIconOnMobile}
      >
        {commonInner}
        {destination.isExternalLink && !ariaLabel && (
          <VisuallyHidden>{destination.newTabText}</VisuallyHidden>
        )}
      </CoreLinkButton>
    );
  } else {
    return (
      <CoreButton
        {...props}
        variant={variant}
        size={size}
        iconPosition={icon ? iconPosition : null}
        hideIconOnMobile={hideIconOnMobile}
      >
        {commonInner}
      </CoreButton>
    );
  }
};

const getButtonStyle = (variant: ButtonVariant) => {
  switch (variant) {
    case 'flat-light':
      return css`
        background: var(--background-subtle);
        border: solid 1px var(--border-warm-base);
        color: var(--text-strong);

        ${IconBackground} {
          background: var(--surface-primary);

          svg {
            color: var(--icon-inverse-strong);
          }
        }

        &:hover {
          background: var(--surface-warm-strong);
          color: var(--text-warm-base);
        }

        &:focus-visible {
          outline: 2px solid var(--border-action);
          outline-offset: 2px;
        }
      `;

    default:
    case 'solid-light':
      return css`
        background: var(--surface-primary);
        color: var(--text-inverse-strong);

        ${IconBackground} {
          background: var(--surface-subtle);

          svg {
            color: var(--icon-action);
          }
        }

        &:hover {
          background: var(--surface-inverse-primary);

          svg {
            color: var(--icon-action-dark);
          }
        }

        &:focus-visible {
          outline: 2px solid var(--border-action);
          outline-offset: 2px;
        }
      `;

    case 'flat-dark':
      return css`
        background: var(--surface-inverse-base);
        border: solid 1px var(--border-inverse-base);
        color: var(--text-inverse-strong);

        ${IconBackground} {
          background: var(--surface-primary);

          svg {
            color: var(--icon-inverse-strong);
          }
        }

        &:hover {
          background: var(--surface-inverse-strong);
        }

        &:focus-visible {
          outline: 2px solid var(--border-inverse-action);
          outline-offset: 2px;
        }
      `;

    case 'solid-dark':
      return css`
        border: none;
        background: var(--surface-subtle);
        color: var(--text-action);

        ${IconBackground} {
          background: var(--surface-primary);

          svg {
            color: var(--icon-inverse-strong);
          }
        }

        &:hover {
          color: var(--text-action-dark);

          ${IconBackground} {
            background: var(--surface-inverse-primary);
          }
        }

        &:focus-visible {
          outline: 2px solid var(--border-inverse-action);
          outline-offset: 2px;
        }
      `;
  }
};

const getIconHideBreakpoint = (hide: boolean) =>
  hide ? screen.sm : '@media (min-width: 0px)';

const getIconPadding = (position: IconPosition, hideIconOnMobile: boolean) => {
  const hideBreakpoint = getIconHideBreakpoint(hideIconOnMobile);

  if (position === 'left')
    return css`
      ${hideBreakpoint} {
        --padding-offset-left-md: -4px;
        --padding-offset-left-lg: -6px;
        --padding-offset-left-xl: -8px;
      }
    `;

  if (position === 'right')
    return css`
      ${hideBreakpoint} {
        --padding-offset-right-md: -4px;
        --padding-offset-right-lg: -6px;
        --padding-offset-right-xl: -8px;
      }
    `;
};

const getSize = (size: ButtonSize) => {
  switch (size) {
    case 'md':
      return css`
        ${labelMedium}

        padding-block: var(--spacing-100);
        padding-left: calc(
          var(--spacing-150) + var(--padding-offset-left-md, 0px)
        );
        padding-right: calc(
          var(--spacing-150) + var(--padding-offset-right-md, 0px)
        );

        height: 40px;
        gap: 8px;
      `;
    case 'lg':
      return css`
        ${labelLarge}

        padding-block: calc((var(--spacing-100) + var(--spacing-25)));
        padding-left: calc(
          var(--spacing-200) + var(--padding-offset-left-lg, 0px)
        );
        padding-right: calc(
          var(--spacing-200) + var(--padding-offset-right-lg, 0px)
        );

        height: 48px;
        gap: 10px;
      `;

    case 'xl':
    default:
      return css`
        ${headingSmall}

        padding-block: calc((var(--spacing-100) + var(--spacing-25)));
        padding-left: calc(
          var(--spacing-250) + var(--padding-offset-left-xl, 0px)
        );
        padding-right: calc(
          var(--spacing-250) + var(--padding-offset-right-xl, 0px)
        );

        height: 56px;
        gap: 12px;
      `;
  }
};

const IconBackground = styled.div`
  border-radius: var(--radius-full);
  display: flex;
  justify-content: center;
  transition: all 0.2s ease;

  padding: 0.375rem;
`;

export const coreStyling = css`
  border: none;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  max-width: 100%;
  width: max-content;

  line-height: var(--line-height-compact);
  font-weight: var(--font-weight-medium);
  border-radius: var(--radius-full);

  display: inline-flex;
  align-items: center;

  position: relative;
  transition: all 0.2s ease;

  &:active {
    transform: scale(0.96);
  }

  &.disabled,
  &:disabled {
    opacity: var(--opacity-disabled);
    pointer-events: none;
  }

  span {
    text-align: center;
  }
`;

const CoreButton = styled.button<
  ButtonType & { iconPosition?: IconPosition; hideIconOnMobile?: boolean }
>`
  ${coreStyling}

  ${(props) => getIconPadding(props.iconPosition, props.hideIconOnMobile)};
  ${(props) => getSize(props.size)};
  ${(props) => getButtonStyle(props.variant)};

  ${IconBackground} {
    display: none;
  }

  ${(props) => getIconHideBreakpoint(props.hideIconOnMobile)} {
    ${IconBackground} {
      display: block;
    }
  }
`;

const CoreLinkButton = styled(Link, { shouldForwardProp: isPropValid })<
  ButtonLinkType & { iconPosition?: IconPosition; hideIconOnMobile?: boolean }
>`
  ${coreStyling}

  ${(props) => getIconPadding(props.iconPosition, props.hideIconOnMobile)};
  ${(props) => getSize(props.size)};
  ${(props) => getButtonStyle(props.variant)};

  ${IconBackground} {
    display: none;
  }

  ${(props) => getIconHideBreakpoint(props.hideIconOnMobile)} {
    ${IconBackground} {
      display: block;
    }
  }
`;

const iconSizeMap = {
  md: '12px',
  lg: '16px',
  xl: '20px',
} as const;

export const IconCircle = ({
  icon = 'ArrowRight',
  size = 'xl',
}: IconButtonProps) => {
  return (
    <IconBackground>
      <Icon icon={icon} size={iconSizeMap[size] ?? iconSizeMap.xl} />
    </IconBackground>
  );
};
