import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { HeroSectionElem } from '@/components/layout/Section/HeroSection';
import { usePageCommon } from '@/lib/usePageCommon';

import { WidthConstraint } from './common';

import type {
  BackgroundColourTokenType,
  SurfaceColourTokenType,
  TextColourTokenType,
} from '@/types/colours';
import type { ReactNode } from 'react';

type VerticalPaddingValues =
  | boolean
  | 'default'
  | 'reduced-top'
  | 'reduced-bottom'
  | 'reduced-top-bottom';

type SpacingValues = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type SpacingMode = 'padding' | 'margin';

interface SectionProps {
  className?: string;
  children?: ReactNode;
  breakout?: boolean;

  spacingMode?: SpacingMode;

  backgroundColour?: BackgroundColourTokenType | SurfaceColourTokenType;
  colour?: TextColourTokenType;
  verticalPadding?: VerticalPaddingValues;
  spacing?: SpacingValues;
}

export const Section = (props: SectionProps) => {
  const { breakout, children, className, spacingMode } = props;
  const { layout } = usePageCommon();

  const innerComponent = breakout ? (
    children
  ) : (
    <WidthConstraint>{children}</WidthConstraint>
  );

  // Local spacingMode is an override from page level
  const pageSpacingMode = spacingMode ?? layout?.spacingMode;

  if (pageSpacingMode === 'margin') {
    const { spacing = 'md' } = props;

    return (
      <SectionMarginElem marginSize={spacing} className={className}>
        {innerComponent}
      </SectionMarginElem>
    );
  } else {
    const {
      backgroundColour = '--background-subtle',
      colour,
      verticalPadding = false,
    } = props;

    return (
      <SectionPaddingElem
        verticalPadding={verticalPadding}
        backgroundColour={backgroundColour}
        colour={colour}
        className={className}
      >
        {innerComponent}
      </SectionPaddingElem>
    );
  }
};

const getVerticalPadding = (verticalPadding: VerticalPaddingValues) => {
  switch (verticalPadding) {
    case 'reduced-top':
      return css`
        padding-top: var(--spacing-100);
        padding-bottom: var(--spacing-400);

        ${screen.md} {
          padding-bottom: var(--spacing-600);
        }

        ${screen.lg} {
          padding-bottom: var(--spacing-800);
        }
      `;

    case 'reduced-bottom':
      return css`
        padding-top: calc(
          var(--spacing-400) - var(--first-section-reducer, 0px)
        );
        padding-bottom: var(--spacing-100);

        ${screen.md} {
          padding-top: calc(
            var(--spacing-600) - var(--first-section-reducer, 0px)
          );
        }

        ${screen.lg} {
          padding-top: calc(
            var(--spacing-800) - var(--first-section-reducer, 0px)
          );
        }
      `;

    case 'reduced-top-bottom':
      return css`
        padding-top: var(--spacing-100);
        padding-bottom: var(--spacing-100);
      `;

    case true:
    case 'default':
      return css`
        padding-top: calc(
          var(--spacing-400) - var(--first-section-reducer, 0px)
        );
        padding-bottom: var(--spacing-400);

        ${screen.md} {
          padding-top: calc(
            var(--spacing-600) - var(--first-section-reducer, 0px)
          );
          padding-bottom: var(--spacing-600);
        }

        ${screen.lg} {
          padding-top: calc(
            var(--spacing-800) - var(--first-section-reducer, 0px)
          );
          padding-bottom: var(--spacing-800);
        }
      `;

    case false:
    default:
      break;
  }
};

type SectionPaddingElemProps = Pick<
  SectionProps,
  'verticalPadding' | 'backgroundColour' | 'colour'
>;

export const SectionPaddingElem = styled.section<SectionPaddingElemProps>`
  position: relative;
  overflow: clip;

  ${HeroSectionElem} + & {
    --first-section-reducer: var(--section-radius);
  }

  ${(props) => css`
    background-color: var(${props.backgroundColour});
    color: var(${props.colour});
  `}

  ${({ verticalPadding }) => getVerticalPadding(verticalPadding)}
`;

type SectionMarginElemProps = { marginSize: SpacingValues };

export const SectionMarginElem = styled.section<SectionMarginElemProps>`
  position: relative;
  overflow: clip;

  margin-top: ${({ marginSize }) => `var(--space-page-${marginSize})`};

  &:last-of-type {
    margin-bottom: calc(var(--space-page-xl) - var(--section-radius));
  }
`;
